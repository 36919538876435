// Easter Egg
import React from "react";
import { useSpring, animated } from "react-spring";
import duwoMockup from "../img/iphoneMock.png";

import agent35101 from "../img/agent35101.png";

function Resume() {
  const props = useSpring({ opacity: 2, from: { opacity: 0 } });
  return (
    <animated.div style={props}>
      <div class="row">
        <div class="one-third column">
          <h3>How did I get into designing web apps?</h3>
        </div>

        <div class="two-thirds column">
          <p class="baseline">
            Long story short: When I was a kid, I taught
            myself how to draw by watching cartoons. I learned human anatomy,
            shading, perspective, inking, and lettering. As I got older, I would
            draw up ideas for video games I’d like to play. I taught myself CSS
            & HTML so I could create my own website to host my comics.
            <br />
            <br />
            My first love is design and understanding how things work. When I
            walk by a beautiful store and it gives me the urge to walk in, I
            take a photo of the storefront to remember exactly what captured my
            attention. My laptop and phone is filled with screenshots of various
            websites. If I like the design language, color palette, branding or
            onboarding process of a particular site, it goes into my inspiration
            folder.
            <br />
            <br />
            When I joined the Navy and became a IT technician and then got out
            working as an IT in Spain, Italy, and Japan. It was at that time I
            would design and create prototypes to solve problems I would
            encounter on a daily basis. I also became obsessed with Japanese
            minimalism which is a major foundation in how I design applications,
            dress, and how I keep my home. My favorite book on the subject is
            “Goodbye Things” by Fumio Sasaki. In design to me less is more.
            <br />
            <br />
            My first web application I designed and implemented for work was a
            tool to help onboard new employees since training them was taking
            away from time I could spend doing my own work. When the iPhone was
            released, my childhood fantasy of designing my own video game came
            true when I created the UI/UX and all the art for a game called
            Agent 351: Logic Bomb. It was a trivia game that reached #12 in the
            iTunes App Store.
            <br />
            <br />
            <img className="resp" src={agent35101} width={300} />
            <br />
            <br />
            A couple years later I designed a 180 level game
            called Pot O’Gold. Because of this modest success, I was invited to
            the IULM in Milan to give lectures atDesign Week (think of fashion
            week but for well, designers) on creating mobile applications. The
            following year I was invited again to discuss the topic “Videos
            Games as Art”.
            <br />
            <br />
            My design and technical skills landed me at Minted.com in Silicon
            Valley where at the growing startup, I did everything from manage an
            IT team to developing the company’s intranet to designing a
            prototype app that would allow people to scan addresses from
            envelopes and save them as text to a users contact book. From there
            my interest in design and development continued working at NTT data
            with multi-national clients, the Social Security Administration and
            SyncLab on various projects. Between projects and jobs, I’d be
            traveling to such places such as Vietnam, Panama, and Hungary.
            <br />
            <br />
            In summary, to me, whether I’m designing a character, a website, or
            custom furniture for my home, Design is everywhere.
          </p>
        </div>
</div>
<div class="row">
        <div class="one-third column">
          <h3>What I'm working on right now</h3>
        </div>
        <div class="two-thirds column">
        <p class="baseline">Right now, I'm working on a graphic novel and a daily activity tracker called DUWO. Here's what it looks like:</p>
        <div className="center">
          <img src={duwoMockup} width={300} />
          </div>
          <p>It's an activity tracker that takes the complexity out of tracking daily fitness. It only asks one question: Did You Work Out Today? 
            I started the process of creating the app by simply using a $1 notebook and began noting how I answered this question. This has been translated into a simple to use application that I hope other people will love to use.
            I'm taking a lot of time designing an easy to use UI that isn't full of numbers and stats, but will however give people everything they need to make a bit of progress in staying healthy every day.  </p>
          </div>
      </div>

      <div className="line-separator"></div>
      {/* SSA */}
      <div class="row">
        <div class="one-third column">
          <h3>Where I've worked</h3>
          <a href="mailto:seanblanchfield.co@gmail.com?subject=Resume request"><p>Need a full resume? contact me</p></a>
          
        </div>

        <div class="two-thirds column">
          <span className="span-fat"><strong>
              Synclab
              </strong>
          </span>
          <span class="baseline">
              frontend developer, technical writing, UI/UX
          </span>
          <br />
          <span className="span-fat"><strong>
              US Social Security Administration
              </strong>
          </span>
          <span class="baseline">
              frontend developer, content & technical writing, UI/UX
          </span>
          <br />
          <span className="span-fat"><strong>
              NTT Data
              </strong>
          </span>
          <span class="baseline">
              frontend developer, content & technical writing, UI/UX
          </span>
          <br />
          <span className="span-fat"><strong>
              NSC Global
              </strong>
          </span>
          <span class="baseline">
              frontend developer, content & technical writing, UI/UX
          </span>
          <br />
          <span className="span-fat"><strong>
              Minted
              </strong>
          </span>
          <span class="baseline">
              technical management, UI/UX development, intranet development
          </span>
          <br />
          <span className="span-fat"><strong>
              SAIC
              </strong>
          </span>
          <span class="baseline">
              software, customer management
          </span>
          <br />
          <span className="span-fat"><strong>
              Computer Sciences Corporation
              </strong>
          </span>
          <span class="baseline">
              Project management, data migration, technical writing
          </span>
          <br />
          <span className="span-fat"><strong>
              US Navy
              </strong>
          </span>
          <span class="baseline">
       Information Systems Technician
          </span>
        </div>


      </div>

      {/* SSA */}
      {/* <div class="row">
        <div class="one-third column">
          <h3>Social Security Administration</h3>
          <p>2022</p>
        </div>

        <div class="two-thirds column">
          <p class="strong">
            <i>
              Worked with the State of California Medical Information Management
              Systems to upgrade, patch and deliver new software and servers.
            </i>
          </p>
          <p class="baseline">
            <ul>
              <li>
                Established compatibility with third party products by
                developing strategies for modification and integration.
              </li>
              <li>
                Wrote scripts for and deployed new servers for core business
                services.
              </li>
              <li>
                Worked with State of California personnel to ensure that
                software functionality and user data consistency prior to
                deployment.{" "}
              </li>
            </ul>
          </p>
        </div>
      </div> */}

      {/* NTT Data */}
      {/* <div class="row">
        <div class="one-third column">
          <h3>NTT DATA</h3>
          <p>2019-2022</p>
        </div>

        <div class="two-thirds column">
          <p class="strong">
            <i>
              Worked with the State of California Medical Information Management
              Systems to upgrade, patch and deliver new software and servers.
            </i>
          </p>
          <p class="baseline">
            <ul>
              <li>
                Established compatibility with third party products by
                developing strategies for modification and integration.
              </li>
              <li>
                Wrote scripts for and deployed new servers for core business
                services.
              </li>
              <li>
                Worked with State of California personnel to ensure that
                software functionality and user data consistency prior to
                deployment.{" "}
              </li>
            </ul>
          </p>
        </div>
      </div>
      <br />
      <br /> */}
      {/* Sacramento */}
      {/* <div class="row">
        <div class="one-third column">
          <h3>NSC Global Services</h3>
          <p>Contractor</p>
          <p>Sacramento, California</p>
          <p>2018</p>
        </div>

        <div class="two-thirds column">
          <p class="strong">
            <i>
              Worked with the State of California Medical Information Management
              Systems to upgrade, patch and deliver new software and servers.
            </i>
          </p>
          <p class="baseline">
            <ul>
              <li>
                Established compatibility with third party products by
                developing strategies for modification and integration.
              </li>
              <li>
                Wrote scripts for and deployed new servers for core business
                services.
              </li>
              <li>
                Worked with State of California personnel to ensure that
                software functionality and user data consistency prior to
                deployment.{" "}
              </li>
            </ul>
          </p>
        </div>
      </div>
      <br />
      <br /> */}
      {/* Minted */}
      {/* <div class="row">
        <div class="one-third column">
          <h3>Minted.com</h3>
          <p>San Francisco, California</p>
          <p>2013-2016</p>
        </div>

        <div class="two-thirds column">
          <p class="baseline">
            <p class="strong">
              <i>
                Performed various duties while working in the IT/Engineering
                department including server management & intranet web
                development.
              </i>
            </p>
            <ul>
              <li>
                I worked with Minted's DevOps teams on updating the live site
                deployment with Jenkins & GitHub
              </li>
              <li>
                Created an unreleased iOS Application by request of the Director
                of Engineering which used the iPhone's camera to capture mailing
                addresses from envelopes and translate them to OCR text.{" "}
              </li>
              <li>
                I created an internal app with PHP to bulk create users with
                Altissan's Hip Chat API{" "}
              </li>
              <li>
                Wrote scripts to manage server backups of Minteds art assets
              </li>
              <li>
                Created Minted's intranet to help the operations department
                manage training for the companies designers and customer service
                personnel by leveraging PHP/Wordpress & AWS.
              </li>
            </ul>
          </p>
        </div>
      </div>
      <br />
      <br /> */}
      {/* SAIC */}
      {/* <div class="row">
        <div class="one-third column">
          <h3>SAIC</h3>
          <p>Contractor</p>
          <p>Naples, Italy</p>
          <p>2008-2012</p>
        </div>

        <div class="two-thirds column">
          <p class="strong">
            <i>
              Diagnose and solved proprietary client software and software-user
              issues from remote locations in addition to remote installations
              and training of the customer in the use of data management
              software.
            </i>
          </p>
          <p class="baseline">
            <ul>
              <li>
                Performed group testing of software prior to network wide
                deployment
              </li>
              <li>
                Planned and performed software installations using IBM's Tivoli
                suite.
              </li>
              <li>
                I created a program in PHP & HTML to onboard new employees.
              </li>
              <li>
                Functioned as technical lead for a department of fifteen
                personnel.
              </li>
            </ul>
          </p>
        </div>
      </div>
      <br />
      <br /> */}
      {/* CSC */}
      {/* <div class="row">
        <div class="one-third column">
          <h3>Computer Sciences Corp.</h3>
          <p>Contractor</p>
          <p>Tokyo, Japan</p>
          <p>2007</p>
        </div>

        <div class="two-thirds column">
          <p class="strong">
            <i>
              Tasked with the gathering of user requirements, defining data
              specifications and implementation of migrating data documents to
              updated software.
            </i>
          </p>
          <p class="baseline">
            <ul>
              <li>
                Performed routine DBA maintenance tasks like planning,
                monitoring databases, logs for errors.{" "}
              </li>
              <li>Implemented database backups and restores when necessary.</li>
              <li>Created Database Views, Triggers, Indexes.</li>
            </ul>
          </p>
        </div> 
      </div>*/}
    </animated.div>
  );
}

export default Resume;
