import React from "react";
import creative from "../../img/diy.png";

const ProlificMaker = () => {
  return (
    <div class="row">
      <div class="one-third column">
        <h1>I am a prolific maker.</h1>
        <img src={creative} style={{width: "64px"}}/>
      </div>

      <div class="two-thirds column">
        <p class="baseline">
          My earliest memories have been of designing solutions. I genuinely
          love: writing one-pagers to pitch a new product or org design idea,
          creating video visiontypes, making presentations to communicate a
          strategic shift...basically, I like to make stuff.
          <br />
          <br />
          Outside of work, I love to read and create things digital and
          analog.In 2011 created the UI and story sequence for a game called
          Agent 351 which reached #12 in the trivia category of the Apple App
          Store. I’ve produced a short animated film and have given lectures at
          the IULM in Milan during Design Week in Milan. I flexed my real world
          UI/UX skills when remodeling my condo by thinking of all the problems
          that existed in my previous home, how to fix them and of course make
          it beautiful and functional. When I’m not creating, you can find me on
          my 1994 Vespa PK 50 discovering back roads or joining athletic
          competitions like the Spartan Race and half marathons.
        </p>
        <div className="info-box-blk">
          <span>  TL;DR:{ " " }</span>
            <p>Design is my happy place and I'm always ready to apply what I learn
            in other hobbies to software.
          </p>
        </div>
        <div className="line-separator"></div>
      </div>
    </div>
  );
};

export default ProlificMaker;
