import React from 'react'
import { Link } from 'react-router-dom'
import { useSpring, animated } from 'react-spring'
// import it from '../../img/itRound.png'
import linkd from '../img/linkedin.svg'
import gh from '../img/github.svg'
import sblogo from '../img/sb-logo.svg'

function Header() {
  const props = useSpring({ opacity: 2, from: { opacity: 0 } })
  return <animated.div style={props}>
    <div>
      <div class="row">
        <h1 class="branding"><Link to="/">Sean Blanchfield</Link></h1>
        <ul class="mainNav">
        <li><Link to="/">★</Link></li>
          <li><Link to="/portfolio">work</Link></li>
          <li><Link to="/approach">approach</Link></li>
          <li><Link to="/resume">resume</Link></li>
          {/* <li><a href="https://github.com/seanbfield" target="_blank"><img src={gh}></img></a></li>
          <li><a href="https://www.linkedin.com/in/sean-blanchfield-b13b2a14b/" target="_blank"><img src={linkd}></img></a></li> */}
        </ul>
      </div>
    </div>
  </animated.div >
}


export default Header