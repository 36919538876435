// Easter Egg
import React from "react";
// import creative from '../../img/creative.png'
import { useSpring, animated } from "react-spring";
import DefineApproach from "./DefineApproach";
import RootProblem from "./RootProblem";
import ThinkingLogic from "./ThinkingLogic";
import ProlificMaker from "./ProlificMaker";

function Approach() {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  return (
    <animated.div style={props}>
      <DefineApproach />
      <RootProblem />
      <ThinkingLogic />
      <ProlificMaker />
    </animated.div>
  );
}

export default Approach;
