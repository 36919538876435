import React from "react";
import creative from "../../img/lego.png";

const RootProblem = () => {
  return (
    <div class="row">
      <div class="one-third column">
        <h1>Less is more.</h1>
        <img src={creative} style={{ width: "110px" }} />
      </div>

      <div class="two-thirds column">
        <p class="baseline">
          My design philosophy is "Less is More". I believe that applications
          should stay out of the way of the user. There shouldn’t be complicated
          manuals or help desk support documents on how to use an application.
          If a program is well designed and free of unnecessary clutter, it
          allows users to complete their tasks quickly and efficiently.
          <br />
          <br />
          A lot of time, designers will try to fill every pixel on the screen
          with something to make a page look "full". Or they may try to fit more
          functions such as forms into one page when it should be actually be
          broken down into several pages making it easier for a user to navigate
          a website and complete their task.
          <br />
          <br />
          To me, it's important that software is functional and beautiful for
          the end user. Through understanding user needs and implementing those
          solutions with good design, a great application will stay out of the
          way.
        </p>
        <div className="info-box-blk">
        <span>  TL;DR:{ " " }</span>
          <p>Less complexity = happy users.
          </p>
        </div>
        <div className="line-separator"></div>
      </div>
    </div>
  );
};

export default RootProblem;
