// Easter Egg
import React from "react";
import "../App.css";
import { useSpring, animated } from "react-spring";
import { Link } from "react-router-dom";
// import it from '../../img/itRound.png'
import linkd from "../img/linkedin.svg";
import gh from "../img/github.svg";
import sblogo from "../img/sb-logo.svg";
import rjs from "../img/react.svg";
import htm5 from "../img/html.svg";
import psq from "../img/postgres.svg";
import cs2 from "../img/css.svg";
import illustrator from "../img/illustrator.svg";
// import invision from "../img/invision.png";
import njs from "../img/node.svg";
import sf from "../img/sean-sf.jpg";
// import Anicons from '../animated/Anicons';

function Home() {
  const props = useSpring({ opacity: 2, from: { opacity: 0 } });
  return (
    <animated.div style={props}>
      <div class="row">
        <div class="one-half column">
          <h1>hi there.</h1>
          <h3>
            I am a UI/UX developer and design oriented web designer who creates products that
            make a positive impact on customer experience and business metrics.{" "}
          </h3>
        </div>

        <div class="one-half column">
          <p class="baseline">
            I am an innovative and agile Web Developer with experience in
            advising, analyzing & implementing software solutions that resolve
            complex problems across a diverse set of industries. Aesthetically
            inclined and consumer focused, I have a passion for creating
            websites and applications that exceed both internal & external
            expectations for the clients I support. I believe that optimal user
            experience, effective design and efficient code is a key
            differentiating value proposition for achieving long-term success.
          </p>
        </div>
        <div class="one-half column">
          <p class="baseline">
            Tools I use: Adobe Illustrator, XD, Photoshop, Procreate, Miro, Zeplin, Visual Studio Code, Github, Figma, React JS, Angular, Material UI, AntDesign,  Tailwind CSS 
            </p>
            </div>
      </div>
          <div className="icon-row">
            <img src={rjs} alt="react js" />
            <img src={njs} alt="node" />
            {/* <img src={psq} alt="postgres" /> */}
            <img src={htm5} alt="html 5" />
            <img src={cs2} alt="css" />
          </div>
    </animated.div>
  );
}
export default Home;
