import React from "react";
import { useSpring, animated } from "react-spring";
import artvard from "../img/artvard.svg";
import snackpage from "../img/snackPage.svg";
import rando from "../img/randomMe.svg";
import firstUsers from "../img/firstUsers.png";
import ssa from "../img/ssa.png";
import gh from "../img/github.svg";
import configurator from "../img/configurator.png";
import appt from "../img/appt.png";
import docomo from "../img/docomo.gif";
import alma1 from "../img/alma1.png";
import alma3 from "../img/alma3.png";
import enel from "../img/enel.png";

export default function Portfolio() {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  return (
    <animated.div style={props}>
      {/* SSA */}
      <p>
        <strong>Here's some of my favorite projects. Most of the good stuff is NDA'd</strong>
      </p>
      <div className="row">
        <div className="header-row">
          <h2>Social Security Card Replacement</h2>
          <a
            href="https://www.ssa.gov/number-card/replace-card"
            target="_blank"
          >
            Live Website
          </a>
        </div>
      </div>
      <div className="row">
        <div className="column">
          <a
            href="https://www.ssa.gov/number-card/replace-card"
            target="_blank"
          >
            <img className="resp" src={ssa} width={400} />
          </a>
        </div>

        <div className="row">
          <div className="column">
            <div className="info-box">
              <h5>Problem</h5>
              <p>
                Every year, millions of Americans need to get a Social Security
                Card. However, many field offices have long lines and
                requirements for what people need to bring with them are not
                clear.{" "}
              </p>
              <h5>Solution</h5>
              <p>
                Working members of the design and development team, we created
                mockups, functional design documents, strategic content, and
                demo apps to present during weekly sprints to get the process
                down before presenting it to the public.{" "}
              </p>
              <h5>Result</h5>
              <p>
                The card tool is now live att ssa.gov saving thousands of hours
                every year for citizens and SSA field offices.
              </p>
            </div>
            <div className="line-separator"></div>
          </div>
        </div>
      </div>

      {/* SnackPage */}
      <div className="row">
        <div className="header-row">
          <h2>Leroy Merlin Appointments</h2>
          <a href="https://presaappuntamento.leroymerlin.it/" target="_blank">
            Live Website
          </a>
        </div>
      </div>
      <div className="row">
        <div className="column">
          <a href="https://www.leroymerlin.it/oaainfissi/" target="_blank">
            <img className="resp" src={appt} width={400} />
          </a>
        </div>

        <div className="row">
          <div className="column">
            <div className="info-box">
              <h5>Problem</h5>
              <p>
                During the COVID-19 pandemic, Leroy Merlin needed users to be
                able to make orders and consult specialized employees about
                products while locations were closed.
              </p>
              <h5>Solution</h5>
              <p>
                I worked with a backend developer and the client's API team to
                develop a product selection and configuration tool. Once the
                user finished selecting products they were sent to a separate
                appointment booking solution where they could set up a video
                conference with a specialist.
              </p>
              <h5>Result</h5>
              <p>
                Leroy Merlin is able to take appointments online and in-store
                using this web application, making it easier for users to
                purchase windows, doors, and other major items for their homes.
              </p>
            </div>
            <div className="line-separator"></div>
          </div>
        </div>
      </div>

      {/* Leroy Merlin */}
      <>
        <div className="row">
          <div className="header-row">
            <h2>Leroy Merlin</h2>
            <a
              href="https://www.leroymerlin.it/oaainfissi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Live Website
            </a>
          </div>
        </div>
        <div className="row">
          <div className="column">
            {/* <img className="resp" src={snackpage} width={400} /> */}
            <a
              href="https://www.leroymerlin.it/oaainfissi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="resp" src={configurator} width={400} />
            </a>
          </div>

          <div className="row">
            <div className="column">
              <div className="info-box">
                <h5>Tech Stack</h5>
                <p>React JS, NodeJS, Redux, Postgres SQL, AWS</p>
              </div>
              <div className="line-separator"></div>
            </div>
          </div>
        </div>
      </>

      {/* Enel Energy */}
      <div className="row">
        <div className="header-row">
          <h2>Enel Energy</h2>
          <a
            href="https://www.enel.it/it/adesione-contratto?productType=res"
            target="_blank"
            rel="noopener noreferrer"
          >
            Live Website
          </a>
        </div>
      </div>
      <div className="row">
        <div className="column">
          {/* <img className="resp" src={snackpage} width={400} /> */}
          <a
            href="https://www.enel.it/it/adesione-contratto?productType=res"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="resp" src={enel} alt="enel portfolio" width={400} />
          </a>
        </div>

        <div className="row">
          <div className="column">
            <div className="info-box">
              <h5>Problem</h5>
              <p>
                Enel Energy need a way to entice users to switch over to their
                services. However, in order to compare rates, potential
                customers needed to add information from their current provider
                which is a time consuming task.
              </p>
              <h5>Solution</h5>
              <p>
                As part of the front end development team tasked with creating
                the UI and implementing it in React, I used the free DropZone
                library in order to let leads upload bills from their current
                provider in order to autofill important data.
              </p>
              <h5>Result</h5>
              <p>
                New clients are now able to quickly convert their current bills
                into relevant data for Enel which allows them to not only
                acquire new customers, but gives them information on competitor
                prices and services.
              </p>
            </div>
            <div className="line-separator"></div>
          </div>
        </div>
      </div>

      {/* Almaviva */}
      <div className="row">
        <div className="header-row">
          <h2>Almaviva</h2>
          <p>private repo</p>
        </div>
      </div>
      <div className="row">
        <div className="column">
          <img className="resp" src={alma1} width={400} />
          <img className="resp" src={alma3} width={400} />
        </div>

        <div className="row">
          <div className="column">
            <div className="info-box">
              <h5>Tech Stack</h5>
              <p>React JS, Redux</p>
            </div>
            <div className="line-separator"></div>
          </div>
        </div>
      </div>

      {/* NTT Docomo */}
      <div className="row">
        <div className="header-row">
          <h2>NTT Docomo</h2>
          <p>private repo</p>
        </div>
      </div>
      <div className="row">
        <div className="column">
          {/* <img className="resp" src={snackpage} width={400} /> */}
          <img style={{ width: "100%" }} src={docomo} alt="ntt" />
        </div>

        <div className="row">
          <div className="column">
            <div className="info-box">
              <h5>Tech Stack</h5>
              <p>PUG/Jade, jQuery, GulpJS</p>
            </div>
            <div className="line-separator"></div>
          </div>
        </div>
      </div>

      {/* SnackPage */}
      <div className="row">
        <div className="header-row">
          <h2>SnackPage (DEMO)</h2>
          <p>private repo</p>
        </div>
      </div>
      <div className="row">
        <div className="column">
          {/* <img className="resp" src={snackpage} width={400} /> */}
          <iframe
            width="100%"
            height="580px"
            src="https://www.youtube.com/embed/CILaaBSp9Co"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <div className="row">
          <div className="column">
            <div className="info-box">
              <h5>About</h5>
              <p>
                Snackpage is a web app that builds single page websites.
                TechStack: Ruby on Rails, ReactJS, Stripe API, MailChimp,
                Facebook API, Firebase
              </p>
            </div>
            <div className="line-separator"></div>
          </div>
        </div>
      </div>

      {/* Barrel Aged */}
      {/* <div className="row">
      <div className="header-row">
        <h2><a href="http://barrel-aged.surge.sh/" target="_blank">Barrel-Aged.</a></h2>
        <a href="https://github.com/seanbfield/barrel-aged" target="_blank"  rel="noopener noreferrer"><img src={gh} alt="github" /></a>
      </div>
    </div>

    <div className="row">
      <div className="column">
        <a href="http://barrel-aged.surge.sh/" target="_blank"><img className="resp" src={ba} width={400} /></a>
      </div>

      <div className="row">
        <div className="column">
          <div className="info-box">
            <h5>Tech Stack</h5>
            <p>React JS, Harvard Art Museum API</p>
          </div>
          <div className="line-separator"></div>
        </div>
      </div>
    </div>

     */}

      {/* Artvard */}
      <div className="row">
        <div className="header-row">
          <h2>SnackPage (UI)</h2>
          <a href="https://github.com/seanbfield/artvard" target="_blank">
            <img src={gh} alt="github" />
          </a>
        </div>
      </div>

      <div className="row">
        <div className="column">
          <img className="resp" src={snackpage} width={400} />
        </div>

        <div className="row">
          <div className="column">
            <div className="info-box">
              <h5>Tech Stack</h5>
              <p>React JS, NODE JS, Postgres</p>
            </div>
            <div className="line-separator"></div>
          </div>
        </div>
      </div>

      {/* Lofi */}
      <div className="row">
        <div className="header-row">
          <h2>1st Users</h2>
        </div>
      </div>

      <div className="row">
        <div className="column">
          <img className="resp" src={firstUsers} width={400} />
        </div>

        <div className="row">
          <div className="column">
            <div className="info-box">
              <h5>Tech Stack</h5>
              <p>
               React, NodeJS, MongoDB
              </p>
              {/* <h5>Solution</h5>
              <p>
                As part of the front end development team tasked with creating
                the UI and implementing it in React, I used the free DropZone
                library in order to let leads upload bills from their current
                provider in order to autofill important data.
              </p>
              <h5>Result</h5>
              <p>
                New clients are now able to quickly convert their current bills
                into relevant data for Enel which allows them to not only
                acquire new customers, but gives them information on competitor
                prices and services.
              </p>*/}
            </div> 
            <div className="line-separator"></div>
          </div>
        </div>
      </div>
    </animated.div>
  );
}
