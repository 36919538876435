import React from "react";
import joat from "../../img/joat.svg";

const ThinkingLogic = () => {
  return (
    <div class="row">
      <div class="one-third column">
        <h1>I'm a jack of many trades</h1>
        <img src={joat} />
      </div>

      <div class="two-thirds column">
        <p class="baseline">
          Not only am I a designer, I am a technical writer and software
          developer. I've written software documentation in order for developers
          to be able to efficiently produce software by helping them understand
          the design language, functionality and user experience of various
          products.
          </p>
          <p>Additionally, because my love of designing software lead me to becoming a front end developer which enables me to extrapolate ideas I have to real world products.  I can develop software in frameworks such as React and Angular, create database ERDs, and implement backends.</p>
          <br />
          <br />
        <div className="info-box-blk">
        <span>  TL;DR:{ " " }</span>
          <p>Problem definition is an important part of my work process. That +
            thinking creatively can lead to unconventional solutions.
          </p>
        </div>
        <div className="line-separator"></div>
      </div>
    </div>
  );
};

export default ThinkingLogic;
