// Easter Egg
import React from 'react';
import Header from './components/Header'
import { Route, Switch } from 'react-router-dom'

import Home from './components/Home'
import Approach from './components/ApproachComponent/Approach'
import Portfolio from './components/Portfolio'
import Resume from './components/Resume'

import './App.css';
function App() {
  return (
    <div class="container is-fluid">
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/approach" component={Approach} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route exact path="/resume" component={Resume} />
      </Switch>
    </div >
  );
}

export default App;
