import React from "react";
import creative from "../../img/pencil_128.png";

const DefineApproach = () => {
  return (
    <div class="row">
      <div class="one-third column">
        <h1>I keep teams excited & motivated.</h1>
        <img src={creative} style={{ height: "72px" }} />
      </div>

      <div class="two-thirds column">
        <p class="baseline">
          I wholeheartedly support and encourage my team and organization. In my
          role as a UI/UX developer and Frontend Developer, my approach involves recognizing the
          strengths of my team members and fostering opportunities for them to
          excel and develop. In the face of complexity or challenges, I
          demonstrate empathy and acknowledge the difficulty, but actively
          collaborate with my team to discover solutions. I am unafraid to
          provide constructive feedback and navigate difficult situations. I
          possess the ability to find positivity even in unfavorable
          circumstances. Identifying emerging leaders and assisting them in
          nurturing the necessary skills to become successful managers and
          leaders themselves brings me immense joy.
          <br />
          <br />I approach my work with utmost dedication, constantly
          considering the well-being of our customers, business, organization,
          and product development team. However, this doesn't imply that we
          can't engage in light-hearted humor, pursue creative endeavors, and
          cherish the time we spend collaborating. I excel when my team finds
          joy and achieves productivity.
        </p>
        <div className="info-box-blk">
        <span>  TL;DR:{ " " }    </span>
        <p> I'm a creative team player that loves what I do.</p>
      
        </div>
        <div className="line-separator"></div>
      </div>
    </div>
  );
};

export default DefineApproach;
